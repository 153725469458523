import React from 'react';
import paperImg from 'assets/images/top/facts/animation-memo-paper.png';
import lineImg from 'assets/images/top/facts/animation-memo-line1.png';
import penImg from 'assets/images/top/facts/animation-memo-pen.png';
import styled from '@emotion/styled';
import { mq } from 'style/variables';

const Container = styled.div`
  position: relative;
  width: 176px;
  height: 176px;
  margin-left: -6px;
  margin-top: 2px;
  margin-bottom: -2px;
  ${mq.onlysp} {
    transform: scale(calc(180 / 176));
    margin: 3px 0 0 2px;
  }
`;

const PaperImage = styled.img`
  position: absolute;
  width: 176px;
  height: 176px;
  top: 0;
  left: 0;
  z-index: 0;
`;

const LineImage = styled.img`
  position: absolute;
  width: 176px;
  height: 176px;
  top: 0;
  left: 0;
  z-index: 1;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const PenImage = styled.img`
  position: absolute;

  top: calc(70.5px); // DESと会話して細かい値を調整
  left: calc(70.76px); // DESと会話して細かい値を調整
  width: calc(68.62px);
  height: calc(55.48px);
  z-index: 2;
  transform-origin: 20% 80%;

  @keyframes wave {
    /* フェードイン */
    0% {
      opacity: 0;
    }
    4% {
      opacity: 1;
    }
    /* フェードイン後待機 */
    8% {
      opacity: 1;
      transform: rotate(0deg);
    }
    /* ペンを回転させる  */
    14% {
      transform: rotate(-30deg);
      transition-timing-function: ease-out;
    }
    20% {
      transform: rotate(0deg);
      transition-timing-function: ease-out;
    }
    /* ペンを回転させる後待機 */
    25% {
      transform: rotate(0deg);
    }
    /* ペンをスライドさせる */
    35% {
      transform: translateX(35px);
    }
    /* フェードアウト */
    65% {
      transform: translateX(35px);
      opacity: 1;
    }
    69% {
      transform: translateX(35px);
      opacity: 0;
    }
    /* フェードアウト後待機 */
    100% {
      transform: translateX(30px);
      opacity: 0;
    }
  }

  /* animation: wave 1.8s ease-in-out infinite; */
  animation: wave 2.6s ease-in-out infinite;
`;

const AnimationMemo: React.FC = () => {
  return (
    <Container>
      <PaperImage src={paperImg} alt="Paper" />
      <LineImage src={lineImg} alt="Line" />
      <PenImage src={penImg} alt="Pen" />
    </Container>
  );
};
export default AnimationMemo;
